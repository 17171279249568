import React, { useEffect, useRef } from 'react';
import './FikirPaneliDetail.css';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import kapak1 from '../assets/pictures/fikirpaneli-sayi-1.png';
import kapak2 from '../assets/pictures/fikirpaneli-sayi-2.png';
import kapak3 from '../assets/pictures/fikirpaneli-sayi-3.png';

const LazyLoadImage = ({ src, alt, className, dataRel, dataHref, dataWidth, dataHeight }) => {
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        imgRef.current.src = src;  // Resim görünüm alanına girdiğinde yüklenir
        observer.unobserve(imgRef.current);  // Yüklendikten sonra observer'ı durdur
      }
    });

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [src]);

  return (
    <img 
      ref={imgRef}
      alt={alt}
      className={className}
      data-rel={dataRel}
      data-href={dataHref}
      data-width={dataWidth}
      data-height={dataHeight}
    />
  );
};

const magazines = [
  {
    id: 1,
    title: 'Fikir Paneli - 1. Sayı',
    img: kapak1,
    alt: 'Fikir Paneli Sayı 1',
    iframeSrc: 'https://online.fliphtml5.com/aazzp/lvuw/',
  },
  {
    id: 2,
    title: 'Fikir Paneli - 2. Sayı',
    img: kapak2,
    alt: 'Fikir Paneli Sayı 2',
    iframeSrc: 'https://online.fliphtml5.com/aazzp/bcfa/',
  },
  {
    id: 3,
    title: 'Fikir Paneli - 3. Sayı',
    img: kapak3,
    alt: 'Fikir Paneli Sayı 3',
    iframeSrc: 'https://online.fliphtml5.com/fjazc/otde/',
  },
];

const FikirPaneliDetail = () => {
  const { id } = useParams();
  const magazine = magazines.find(mag => mag.id === parseInt(id));

  if (!magazine) {
    return <div>Dergi bulunamadı</div>;
  }

  return (
    <div>
      <Container className="magazine-container mt-5">
        <Row>
          <Col md={4} sm={6} xs={12} className="mb-4">
            <Card className="magazine-card">
              <LazyLoadImage
                src={magazine.img}
                alt={magazine.alt}
                className="magazine-image"
                dataRel="fh5-light-box-demo"
                dataHref={magazine.iframeSrc}
                dataWidth="800"
                dataHeight="600"
              />
              <Card.Body>
                <Card.Title className="text-center">{magazine.title}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md={8} sm={6} xs={12}>
            <iframe 
              src={magazine.iframeSrc} 
              width="100%" 
              height="600px" 
              frameBorder="0" 
              allowFullScreen
              title={magazine.title}
            ></iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FikirPaneliDetail;
