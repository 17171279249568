import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';  // Import Bootstrap
import './FikirPaneliMagazine.css'; // Custom CSS
import kapak1 from '../assets/pictures/fikirpaneli-sayi-1.png';
import kapak2 from '../assets/pictures/fikirpaneli-sayi-2.png';
import kapak3 from '../assets/pictures/fikirpaneli-sayi-3.png';
import { useNavigate } from 'react-router-dom';  // Import useNavigate from react-router-dom
import { useTranslation } from 'react-i18next'; // Import useTranslation from react-i18next

const magazines = [
  {
    id: 1,
    issue: '1',
    imgSrc: kapak1,
    title: 'Fikir Paneli Dergisi',
    description: 'magazine.description',
  },
  {
    id: 2,
    issue: '2',
    imgSrc: kapak2,
    title: 'Fikir Paneli Dergisi',
    description: 'magazine.description',
  },
  {
    id: 3,
    issue: '3',
    imgSrc: kapak3,
    title: 'Fikir Paneli Dergisi',
    description: 'magazine.description',
  }
];

const FikirPaneliMagazine = () => {
  const { t } = useTranslation(); // Translation hook
  const navigate = useNavigate();

  const handleViewClick = (id) => {
    navigate(`/fikirpanelidetail/${id}`);
  };

  return (
    <div className="fikir-paneli-magazine-container container">
      <h2 className="text-center my-4">{t('magazine.title')}</h2>
      <div className="row">
        {magazines.map(magazine => (
          <div className="col-lg-4 col-md-6 mb-4" key={magazine.id}>
            <div className="fikir-paneli-card card h-100">
              <img src={magazine.imgSrc} className="card-img-top fikir-paneli-card-img" alt={magazine.title} />
              <div className="card-body">
                <h5 className="card-title">{t('magazine.issue')} {magazine.issue}</h5>
                <p className="card-text text-muted">{magazine.date}</p>
                <p className="card-text">{t(magazine.description)}</p>
              </div>
              <div className="card-footer text-center">
                <button onClick={() => handleViewClick(magazine.id)} className="btn fikir-paneli-btn-view ml-2 mr-2 w-100">
                  {t('magazine.button_view')}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FikirPaneliMagazine;
